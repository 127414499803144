<script setup lang="ts">
interface Props {
  email: string;
}

interface Emit {
  (e: "show-login"): void;
}

defineProps<Props>();
const emit = defineEmits<Emit>();
const route = useRoute();

const backToLogin = () => {
  if (route.fullPath === ROUTES.login) return emit("show-login");
  return navigateTo(ROUTES.login);
};
</script>

<template>
  <Card class="relative w-full border-1 border-light-gray">
    <div class="text-center text-dark-gray">
      <h4 class="mb-4">Verify your email</h4>
      <div class="flex flex-col gap-4">
        <p>Please verify your email address before using {{ $config.public.appName }}.</p>
        <p>
          An email verification was sent to <strong>{{ email }}</strong> upon registering.
        </p>
        <p>If you have not received the verification email, please check your "Spam" or "Bulk Email" folder.</p>
      </div>
      <p class="text-sm mt-5 text-center">
        Not supposed to be here?
        <span @click="backToLogin" class="link"> Back to Login </span>
      </p>
    </div>
  </Card>
</template>
